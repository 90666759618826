// import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Input, Form, FormFeedback, Button, Spinner, Table } from "reactstrap";
import { Label, InputGroup, Card, CardBody, CardHeader } from "reactstrap";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Select from "react-select"
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import fn_helper from "helpers/fn_helper";
import { ReactSortable } from "react-sortablejs";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";

const DisplayChoice = ({
    idx = 0,
    rowData = {},
    rowsLength = 0,
    onChangeInput = () => console.log('onChangeInput'),
    onMove = (action, index) => console.log('onMove')
}) => {
    // CENTER STATE
    const { questionSet = [] } = useQuestionnaireStore(state => state); // STATE
    const { setQuestionSet } = useQuestionnaireStore(); // ACTION

    const ddSelect = [...Array(6)].map((item, i) => ({ id: `${i}`, name: `${i}` }))

    const mapText = { 'multi': 'Multiple Choice', 'single': 'Single Answer',  }

    const onRemoveRow = (_index = -1) => {
        console.log("🚀 ~ onRemoveRow ~ _index:", _index)
        let tmpRows = _.cloneDeep(questionSet)
        tmpRows = tmpRows.filter((item, i) => i !== _index)
        console.log("🚀 ~ onRemoveRow ~ tmpRows:", tmpRows)
        setQuestionSet(tmpRows)

    }
    const onChangeInputOne = (_field = '', _index = -1, _value ) => {
        console.log("🚀 ~ onChangeInputOne ~ _field:", _field)
        console.log("🚀 ~ onChangeInputOne ~ _index:", _index)
        console.log("🚀 ~ onChangeInputOne ~ _value:", _value)
        let tmpRows = _.cloneDeep(questionSet)
        tmpRows[_index][_field] = _value
        console.log("🚀 ~ onChangeInputOne ~ tmpRows:", tmpRows)
        setQuestionSet(tmpRows)

    }
    const onChangeMutiInput = (_field = [], _index = -1, _value = [] ) => {
        console.log("🚀 ~ onChangeMutiInput ~ _field:", _field)
        console.log("🚀 ~ onChangeMutiInput ~ _index:", _index)
        console.log("🚀 ~ onChangeMutiInput ~ _value:", _value)
        let tmpRows = _.cloneDeep(questionSet)
        for (let i in _field) {
            const field = _field[i]
            const value = _value[i]
            tmpRows[_index][field] = value
        }
        console.log("🚀 ~ onChangeMutiInput ~ tmpRows:", tmpRows)
        setQuestionSet(tmpRows)
    }
    
    const index = idx;

    const ddGoto = fn_helper.OPTIONS.GOTO(rowsLength, index)

    return (
        <Row key={index} className="sortable">
            <Col>
                <Card className="bg-secondary-subtle">
                    <CardHeader className="bg-transparent fs-6 text">
                        <Row>
                            <Col>
                                <div className="d-flex align-item-center gap-1">
                                    <i className='bx bx-grid-vertical handle' style={{cursor: "grab", fontSize: '20px'}}></i> 
                                    <div>
                                        {
                                            index > 0 && (
                                                <i 
                                                    className='bx bx-caret-up' 
                                                    style={{ cursor: 'pointer', fontSize: '20px' }}
                                                    onClick={() => onMove('UP', idx)}
                                                ></i>
                                            )
                                        }
                                        {
                                            (rowsLength - 1) > index && (
                                                <i 
                                                    className='bx bx-caret-down' 
                                                    style={{ cursor: 'pointer',  fontSize: '20px' }}
                                                    onClick={() => onMove('DOWN', idx)}
                                                ></i>
                                            )
                                        }
                                    </div>
                                    <span className="align-middle">{mapText[rowData.question_type] ?? '-'}</span>
                                </div>
                            </Col>
                            <Col>
                                <button
                                    key={idx}
                                    type="button"
                                    className="btn-close align-middle"
                                    style={{position: 'absolute', right: 0}}
                                    aria-label="Close"
                                    onClick={() => {
                                        onRemoveRow(index)
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {/* Question */}
                        <Row className="mb-3">
                            <Col>
                                <Label
                                    htmlFor="example-search-input"
                                    className="col-form-label"
                                    >
                                    Question {index + 1}
                                </Label>
                                <div>
                                    <Input
                                        className="form-control mw-100"
                                        type="text"
                                        name="question_text"
                                        placeholder="eg. What do you do?"
                                        value={rowData.question_text}
                                        onChange={(el) => {
                                            onChangeInputOne('question_text', index, el.target.value)
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {
                            rowData.choice_list.map((choice, index0, arr = []) => {
                                return (
                                    <Row className="mb-3" key={index0}>
                                        <Col sm={11}>
                                            <InputGroup >
                                                <span className="input-group-text">
                                                    {
                                                        rowData.question_type === 'multi'
                                                        ? (
                                                            <Input
                                                                className=""
                                                                type="checkbox"
                                                                checked={choice.is_ans}
                                                                onClick={(el) => {
                                                                    let updateChoiceList = _.cloneDeep(rowData.choice_list)
                                                                    const value = choice.is_ans
                                                                    updateChoiceList[index0]['is_ans'] = !value
                                                                    onChangeInputOne('choice_list', index, updateChoiceList)
                                                                }}
                                                            />
                                                        )
                                                        : (
                                                            <Input
                                                                className=""
                                                                type="radio"
                                                                checked={rowData.ans_choice_single === `${index0}`}
                                                                onClick={(e)=> {
                                                                    const value = `${index0}`
                                                                    onChangeInputOne('ans_choice_single', index, value)
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </span>
                                                <Input 
                                                    className="form-control"
                                                    type="text"
                                                    name="choice_text_1"
                                                    placeholder="eg. Eating"
                                                    value={choice.text}
                                                    onChange={(el) => {
                                                        let updateChoiceList = _.cloneDeep(rowData.choice_list)
                                                        updateChoiceList[index0]['text'] = el.target.value
                                                        onChangeInputOne('choice_list', index, updateChoiceList)
                                                    }}
                                                />
                                            </InputGroup>
                                            
                                        </Col>
                                        <Col sm={1} style={{ padding: 0 }} >
                                            {
                                                arr.length > 1
                                                ? (
                                                    <Button
                                                        color="danger"
                                                        type="button"
                                                        onClick={() => {
                                                            // remove(index)
                                                            let updateChoiceList = _.cloneDeep(rowData.choice_list)
                                                            updateChoiceList = updateChoiceList.filter((item, i) => i !== index0)
                                                            // updateChoiceList[index0]['text'] = el.target.value
                                                            onChangeInputOne('choice_list', index, updateChoiceList)
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                )
                                                : <></>
                                            }
                                        </Col>
                                    </Row>
                                    
                                )
                            })
                        }
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                                    let insertChoiceList = _.cloneDeep(rowData.choice_list)
                                    insertChoiceList.push({ is_ans: false, text: "" })
                                    onChangeInputOne('choice_list', index, insertChoiceList)
                                }
                            }
                        >
                            Add Choice
                        </Button>

                        <hr />
                        <Row>
                            <Col>
                                <Row className="row-cols-md-auto g-3 align-items-center">
                                    <Col>
                                        <div className="align-middle">
                                            <div className="form-check form-switch " >
                                            <Input
                                                    id={'ai_addon_questions_'+ index}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={rowData.ai_addon_questions}
                                                    onClick={(el) => {
                                                        const value = rowData.ai_addon_questions
                                                        onChangeInputOne('ai_addon_questions', index, !value)
                                                    }}
                                                    disabled={rowData.goto === 'End Questionnaire'}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor={'ai_addon_questions_'+ index}
                                                >
                                                    AI add-on Questions
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="align-middle">
                                        <div className="">
                                            {/* <Select
                                                placeholder="Select"
                                                value={fn_helper.FNFORM.getObjectValue(ddSelect, `${rowData.ask_number_question}`)}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(value) => {
                                                    const valueId = value.id
                                                    onChangeInputOne('ask_number_question', index, valueId)
                                                }}
                                                options={ddSelect}
                                                isDisabled={rowData.ai_addon_questions === false}
                                            /> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="row-cols-md-auto g-3 align-items-center d-flex justify-content-end">
                                    <Col>
                                        <span className="align-middle float-right">
                                            <Label htmlFor={`goto_` + index} className="col-form-label">Goto</Label>
                                        </span>
                                    </Col>
                                    <Col md={4}>
                                        <div className="align-middle">
                                            {/* {JSON.stringify(rowData.goto)} */}
                                            {
                                                rowData.goto 
                                                ? (
                                                    <Select
                                                        id={`goto_` + index}
                                                        placeholder="Select"
                                                        value={fn_helper.FNFORM.getObjectValue(ddGoto, `${rowData.goto}`)}
                                                        getOptionValue={(option) => option.id}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={(value) => {
                                                            const valueId = value.id
                                                            onChangeInputOne('goto', index, valueId)
                                                        }}
                                                        options={ddGoto}
                                                    />
                                                )
                                                : <></>
                                            }
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}
export default withTranslation()(DisplayChoice)